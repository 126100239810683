import './stylesheets/App.css';

import footer from './assets/navbar/footer.png'

import { useEffect, useState } from 'react';
import VideoCarousel from './VideoCarousel';
import qr from './assets/qr.svg'

import text from './assets/navbar/text.svg';
import acn from './assets/navbar/acn.svg';
import Vector from './assets/navbar/Vector.svg';
import weather from './assets/Group 46.svg'
import android from './assets/android.svg';
import apple from './assets/apple.svg';
import apple_appstore from './assets/apple.png';
import android_appstore from './assets/android.png';

import group from './assets/navbar/Group 47.png'
import amplifyconfig from './aws-exports';
import { Amplify } from "aws-amplify";
import { enable, disable, record, configureAutoTrack } from 'aws-amplify/analytics';

Amplify.configure(amplifyconfig);

configureAutoTrack({
  enable: true,
  type: 'session',
});

function NavBar(isMobile) {
  const windowWidth = window.innerWidth
  console.log(windowWidth)
  return (
    <span className='navbar-bg'>
      {/* <span style={{display: 'flex'}}>
        <img src={seeyou} />
        <img src={lasvegas} />
      </span> */}
      <span className='see-vegas-span'>
        {/* <img src={lasvegas} className='see-in-vegas' /> */}
        <img src={group} className='see-in-vegas' />

      </span>

      <img src={Vector} className='vector' />

    </span>
  )
}


function App() {

  const [mobile, setMobile] = useState(window.innerWidth <= 760);
  const [mobileType, setMobileType] = useState('unspecified')
  const [phoneType, setPhoneType] = useState('android');

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth <= 760);
  }

  const changePhone = (val) => {
    console.log(val);
    val ? setPhoneType('apple') : setPhoneType('android');
  }

  const changePhoneType = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    console.log(userAgent)
    if (/android/i.test(userAgent)) {
      console.log(/android/i.test(userAgent))
      setMobileType("android");
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      console.log(/iPad|iPhone|iPod/.test(userAgent))
      setMobileType("ios");
    }

  }


  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    changePhoneType();
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  return (
    <div className="App">
      {/* header */}
      {/* <div  style={{}}> */}
      {/* <img src={mobile ? headerMobile : header} className='header-row' /> */}
      <NavBar isMobile={mobile} />
      {/* </div> */}

      <VideoCarousel />

      <div className='see-soon'>
        <div className='see-soon-inner'>
          <span className='see-soon-title'>SEE YOU SOON!</span >
          <p className='see-soon-subtitle'>
            We are looking forward to seeing you. The weekend
            promises to be time well spent and we’re glad you’ve decided to join us. Safe travels!</p>
          <span className='see-soon-weather'>
            <img src={weather}></img>
            <a target="_blank" href="https://weather.com/weather/tenday/l/ecf8be4edebe4ab82252eeb8b0f2bb4df1ea68976304086b2e901709fce8dc24">
              <p className='see-soon-subtitle forecast' >Las Vegas 10 Day Forecast</p ></a>
          </span>
        </div>
      </div>


      {/* QR ROw */}
      <div className='box qr-box'>

        <div className='row-content' style={{ width: '100%' }}>
          <div className='qr-subtitle' style={{ textAlign: 'center' }}>Follow these instructions to stay in the know of all the weekend happenings</div>
          {mobile ?
            <div className='qr-switch'>

              <div className='app-download'>
                {(mobileType === 'android' || mobileType == 'unspecified') && <a href="https://apps.apple.com/us/app/cvent-events/id1491335576"><img className={'app-icon'} src={android} /></a>}
                {((mobileType === 'ios' || mobileType == 'unspecified') && <a href="https://apps.apple.com/us/app/cvent-events/id1491335576"><img className={'app-icon'} src={apple} /></a>)}
              </div>
            </div>

            : ''}
          <div className=' qr-row'>
            <span className='qr-left'>
              <table>
                <tbody>
                  <tr>
                    <td className="table-d">Step 1:</td>
                    <td>Open the app and search for the event name: <span style={{ textDecoration: 'underline' }}>Big Game</span></td>
                  </tr>
                  <tr>
                    <td className="table-d">Step 2:</td>
                    <td>Click the download arrow</td>
                  </tr>
                  <tr>
                    <td className="table-d">Step 3:</td>
                    <td>Type in the event ID: <span style={{ textDecoration: 'underline' }}>ACCBigGame58</span> (no spaces)</td>
                  </tr>
                  <tr>
                    <td className="table-d">Step 4:</td>
                    <td>Click the download event button</td>
                  </tr>
                  <tr>
                    <td className="table-d">Step 5:</td>
                    <td>Type in your first name, last name, and email used to register for this event.</td>
                  </tr>
                  <tr>
                    <td className="table-d">Step 6:</td>
                    <td>Check your email for a verification code to complete your login and access the weekend details.</td>
                  </tr>
                </tbody>
              </table>
              <br />
              Note: This code is good for one use and expires after 24 hours.
            </span>

            {mobile ?
              ''

              : <div className='qr-switch'>

                {/* <img src={qr}></img> */}
                <img src={phoneType === 'apple' ? apple_appstore : android_appstore} width={'100%'}></img>

                <span className='qr-span'>
                  Google Play
                  <label className="switch">
                    <input type="checkbox" onChange={(event) => changePhone(event.target.checked)} />
                    <span className="slider round"></span>
                  </label>
                  App Store
                </span>
                Toggle based on phone type

              </div>
            }


          </div>

          {/* <span>OR</span>
  <a href='https://ts.accenture.com/sites/NAMarketHive/_layouts/15/AccessDenied.aspx?Source=https%3A%2F%2Fts%2Eaccenture%2Ecom%2F%3Aw%3A%2Fr%2Fsites%2FNAMarketHive%2FShared%20Documents%2FGeneral%2F02%20Iconic%20Programs%2FNA%20Business%20Partners%2FSuper%20Bowl%20%2D%20Las%20Vegas%20%2D%202024%2FCommunications%2FCVENT%20App%2FSuper%20Bowl%20LVIII%20%2D%20Download%20Our%20Mobile%20App%2Edocx%3Fd%3Dwecb13331c19c4f24b15d617b07e983b2%26csf%3D1%26web%3D1%26e%3DxneFN8&correlation=9fc6ffa0%2D90b7%2D4000%2Db31f%2Df816d5ac4b02&Type=item&name=c1e880a8%2D7a21%2D4b88%2Dafe4%2Dadbef5a79db4&listItemId=5384&listItemUniqueId=ecb13331%2Dc19c%2D4f24%2Db15d%2D617b07e983b2'>Download Cvent app HERE</a> */}
        </div>
      </div>

      {/* LOGISITICS ROW */}
      <br />
      <div className='title'>Logistics</div>

      <div className='trans-row row'>

        {/* AIRPORT */}
        <div className='trans-container'>
          <div className='subtitle'>Airport</div>

          <div className='box trans-box'>

            <div className='row-content' style={{ display: 'block' }}>
              <span style={{ textDecoration: 'underline' }}>Arrival</span>
              <br />If you requested ground transportation from the airport upon arrival, a greeter with an Accenture sign in the baggage claim area will assist with coordinating your ride to the hotel.
              <br />
              <br />Should your flight information change, or you can't find the greeter, please contact +1 847-722-7062.

              <br /><br /><span style={{ textDecoration: 'underline' }}> Departure</span>
              <br />Departure information will be provided in your guest room upon check-in.
            </div>
          </div>
        </div>

        {/* HOTEL ACCOM */}
        <div className='trans-container'>
          <div className='subtitle'>Hotel Accomodation</div>

          <div className='box trans-box'>
            {/* <div className='subtitle'>Hotel Accomodation</div> */}
            <div className='row-content' style={{ display: 'block', whiteSpace: 'pre-wrap' }}>
              {`Four Seasons Las Vegas
3960 S Las Vegas Blvd, Las Vegas, NV 89119

A uniformed Accenture greeter will be waiting in the lobby of the Four Seasons Las Vegas to welcome you.
 
Room arrangements have been made for you based on the information you provided during registration.`}
            </div>
          </div>
        </div>

        {/* EVENTS */}
        <div className='trans-container'>
          <div className='subtitle'>Game Day</div>

          <div className='box trans-box'>
            {/* <div className='subtitle'>Events</div> */}
            <div className='row-content' style={{ whiteSpace: 'pre-wrap', alignItems: 'flex-start' }}>
              Sunday, Feb 11 <br />
              <span><span style={{ fontFamily: 'Graphik-Bold' }}>12:00PM: </span> Meet in the Four Seasons Hotel lobby to walk as a group over to Allegiant Stadium.</span>

              <span><span style={{ fontFamily: 'Graphik-Bold' }}>Stadium Guide:</span> <a target="_blank" href='https://www.allegiantstadium.com/plan-your-visit/a-z-guide'>Allegiant Stadium Guide</a> </span>

              <span><span style={{ fontFamily: 'Graphik-Bold' }}>Getting There:</span> <a target="_blank" href='https://www.allegiantstadium.com/plan-your-visit/directions-parking'>Directions & Parking </a> (for walking directions scroll down to "Walking From The Strip")</span>



              <span><span style={{ fontFamily: 'Graphik-Bold' }}>Entering the Stadium:</span> We will enter Allegiant Stadium via the West VIP Stadium Doors.</span>

              <span><span style={{ fontFamily: 'Graphik-Bold' }}>Ticket Delivery:</span> Your digital ticket will be in your email inbox Sunday morning before the Accenture Brunch at the Four Seasons Hotel.</span>


              <span><span style={{ fontFamily: 'Graphik-Bold' }}>What to Bring:</span>  <a target="_blank" href='https://www.allegiantstadium.com/plan-your-visit/bag-check-policy'>Bag Check Policy </a><br /> For personal items you’d like to bring into the Stadium, use the official Super Bowl branded clear tote bag provided to you in your guest room.
              </span>

              <span><span style={{ fontFamily: 'Graphik-Bold' }}>Prohibited items:</span> Prohibited bags include but are not limited to purses or bags larger than 4.5" x 6.5", coolers, briefcases, backpacks, fanny packs, cinch bags/draw strings that are not clear or exceed 12" x 6" x 12". Click <a target="_blank" href='https://www.allegiantstadium.com/plan-your-visit/a-z-guide'>here</a> and scroll to Prohibited Items for the full list.
              </span>


              <span><span style={{ fontFamily: 'Graphik-Bold' }}>Suite Detail:</span>  Accenture Private Club Level Suite – <a target="_blank" href='https://www.allegiantstadium.com/plan-your-visit/stadium-maps'>W2055</a>
              </span>

            </div>
          </div>
        </div>

      </div>



      {/* BOTTOM ROW */}
      <div className='bottom-row'>

        <div className='health-row row'>
          <div className='title'>Health & Safety</div>

          <div className='box health-box'>
            <div className='row-content'> Our health and safety protocols have recently evolved. Vaccines are not a requirement of attendance. COVID-19 testing and masking are also not required. Note: At the event we will have test kits available for anyone who needs one and masks for those who make the personal choice to wear one.
            </div>
          </div>

          <div className='title'>Contact</div>
          <div className='box health-box'>
            <div className='row-content'> If you need anything, please contact our team at BigGameLVIII@accenture.com or you may reach us on-site at +1 847-722-7062.
            </div>
          </div>
        </div>


      </div>
      <div>

      </div>

      <div className='footer-row'>
        <img src={acn} className='footer-row-acn' />

        <img src={footer} className='footer-row-img' />

      </div>
    </div>


  );
}

export default App;
